import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpointsWithVars } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  transactionId: 0,
  isInTransaction: false,
  transaction: {
    transactionId: 0,
    customerResponse: {
      fullName: ''
    },
    creditcardResponse: {
      id: 0,
      number: '',
      number1: '',
      number2: '',
      number3: '',
      number4: '',
      security: '',
      month: 0,
      year: 0,
      fullDate: ''
    }
  }
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getTransactionSuccess(state, action) {
      state.isLoading = false;
      state.transaction = action.payload;
      state.isInTransaction = true;
    },

    completeTransActionSucces(state, action) {
      state.isLoading = false;
      state.isInTransaction = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function getTransaction(supplier, newaccount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(
          apiEndpointsWithVars([supplier, newaccount]).transaction.new
        )
      );
      dispatch(slice.actions.getTransactionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function completeTransaction(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpointsWithVars([id]).transaction.complete)
      );
      dispatch(slice.actions.completeTransActionSucces(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
