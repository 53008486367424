import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpoints } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false
};

const slice = createSlice({
  name: 'queuetogoinstances',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    startInstancesSuccess(state, action) {
      state.isLoading = false;
    },

    killInstancesSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function apiStartInstances(amount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpoints.queuetogoinstance.start),
        amount
      );
      dispatch(slice.actions.startInstancesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function apiKillInstances(command) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpoints.queuetogoinstance.kill)
      );
      dispatch(slice.actions.killInstancesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
