import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getInitialize } from 'src/redux/slices/authJwt';
import axios from 'axios';
import { handleJWT, failInitialize } from 'src/redux/slices/authJwt';
import { InvalidTokenError } from 'jwt-decode';
import { isUrlToApi, isUrlToRefresh } from 'src/utils/requests';

// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node
};

function JwtProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInitialize());
  }, [dispatch]);

  //response interceptor to refresh token on receiving token expired error
  axios.interceptors.request.use(
    async (config) => {
      if (isUrlToRefresh(config.url) || !isUrlToApi(config.url)) return config;

      try {
        let storedAccessToken = window.localStorage.getItem('accessToken');
        let refreshToken = window.localStorage.getItem('refreshToken');
        const { user, accessToken } = await handleJWT(
          storedAccessToken,
          refreshToken
        );

        if (!user) dispatch(failInitialize(dispatch));

        config.headers.Authorization = `Bearer ${accessToken}`;
      } catch (e) {
        if (e instanceof InvalidTokenError) {
          dispatch(failInitialize(dispatch));
        }
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return <>{children}</>;
}

export default JwtProvider;
