import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpoints } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  sectionEvents: [],
  levelEvents: []
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getSectionEventsSuccess(state, action) {
      state.isLoading = false;
      state.sectionEvents = action.payload;
    },

    getLevelEventsSuccess(state, action) {
      state.isLoading = false;
      state.levelEvents = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getSectionEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.events.section)
      );
      dispatch(slice.actions.getSectionEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLevelEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(createApiUrl(apiEndpoints.events.level));
      dispatch(slice.actions.getLevelEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventDetail() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(createApiUrl(apiEndpoints.events.all));
      dispatch(slice.actions.getLevelEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
