import React from 'react';
import { MIcon } from 'src/theme';
import { PATH_APP } from 'src/routes/paths';
import PermMediaIcon from '@material-ui/icons/PermMedia';

// ----------------------------------------------------------------------

const path = (name) => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  events: <PermMediaIcon />
};

const navConfig = [
  // PRODUCTS
  // ----------------------------------------------------------------------
  {
    subheader: 'events',
    items: [
      {
        title: 'events',
        icon: ICONS.events,
        href: PATH_APP.events.root,
        items: [
          {
            title: 'Section Events',
            href: PATH_APP.events.childeren.section
          },
          {
            title: 'Level Events',
            href: PATH_APP.events.childeren.level
          }
        ]
      }
    ]
  },

  {
    subheader: 'QueueToGo',
    items: [
      {
        title: 'QueueToGo',
        icon: ICONS.chat,
        href: PATH_APP.queuetogo.root,
        items: [
          {
            title: 'Dashboard',
            href: PATH_APP.queuetogo.childeren.dashboard
          },
          {
            title: 'Instances',
            href: PATH_APP.queuetogo.childeren.instances
          },
          {
            title: 'SMS',
            href: PATH_APP.queuetogo.childeren.sms
          },
          {
            title: 'Ticketmaster',
            href: PATH_APP.queuetogo.childeren.ticketmaster
          },
          {
            title: 'Settings',
            href: PATH_APP.queuetogo.childeren.settings
          },
          {
            title: 'Client',
            href: PATH_APP.queuetogo.childeren.client
          },
          {
            title: 'Beastmode',
            href: PATH_APP.queuetogo.childeren.beastmode
          }
        ]
      }
    ]
  },

  {
    subheader: 'Transactions',
    items: [
      {
        title: 'New Transaction',
        icon: ICONS.calendar,
        href: PATH_APP.transaction.root
      }
    ]
  },

  {
    subheader: 'Stock',
    items: [
      {
        title: 'Stock overview',
        icon: ICONS.calendar,
        href: PATH_APP.stock.root
      }
    ]
  },

  {
    subheader: 'KvJ Backups',
    items: [
      {
        title: 'KvJ Backups',
        icon: ICONS.upload,
        href: PATH_APP.backup.root
      }
    ]
  }
];

export default navConfig;
