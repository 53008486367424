export const releaseVersion = process.env.RELEASE_VERSION;

export const mapConfig = {
  apiGoogle: process.env.REACT_APP_MAP_GOOGLE,
  apiMapBox: process.env.REACT_APP_MAP_MAPBOX
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL
};

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

const devApiConfig = {
  apiBaseUrl: 'http://localhost:7000',
  backupApiBaseUrl: 'http://127.0.0.1:8080',
  kvjApiBaseUrl: 'http://127.0.0.1:8080'
};

const prodApiConfig = {
  apiBaseUrl: 'https://api.bravo.98k.nl',
  backupApiBaseUrl: 'https://backupapi.bravo.98k.nl',
  kvjApiBaseUrl: 'https://im.goticketshop.nl'
};

export const apiConfig =
  process.env.NODE_ENV === 'development' ? devApiConfig : prodApiConfig;

export const sentryProjectName = process.env.REACT_APP_SENTRY_PROJECT_NAME;
