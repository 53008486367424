import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpoints } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  rows: [],
  command: '',
  users: []
};

const slice = createSlice({
  name: 'queueuser',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getQueueUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(createApiUrl(apiEndpoints.users.list));
      dispatch(slice.actions.getQueueUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
