import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import eventReducer from './slices/event';
import apiKeyreducer from './slices/apiKey';
import imageReducer from './slices/image';
import queueReducer from './slices/queueuser';
import authJwtReducer from './slices/authJwt';
import settingsReducer from './slices/settings';
import calendarReducer from './slices/calendar';
import notificationsReducer from './slices/notifications';
import queuetogoReducer from './slices/queuetogo';
import stockReducer from './slices/stock';
import ticketmasterReducer from './slices/ticketmaster';
import backupReducer from './slices/backup';
import transactionReducer from './slices/transaction';
import queuetogoinstancesReducer from './slices/queuetogoinstances';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'authJwt',
  storage: storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  ticketmaster: ticketmasterReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  queuetogo: queuetogoReducer,
  notifications: notificationsReducer,
  image: imageReducer,
  event: eventReducer,
  stock: stockReducer,
  apiKey: apiKeyreducer,
  queueuser: queueReducer,
  queuetogoinstances: queuetogoinstancesReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  backup: backupReducer,
  transaction: transactionReducer
});

export { rootPersistConfig, rootReducer };
