import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpoints } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  unbookedStock: [],
  stockEvents: []
};

const slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getUnbookedStock(state, action) {
      state.isLoading = false;
      state.unbookedStock = action.payload;
    },

    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.stockEvents = action.payload;
    },

    updateOrderRowsSuccess(state, action) {
      state.isLoading = false;
    },

    addOrderRowsSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function getUnbookedStock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.stock.unbooked)
      );
      dispatch(slice.actions.getUnbookedStock(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(createApiUrl(apiEndpoints.stock.events));
      dispatch(slice.actions.getEventsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteOrdersApi(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(createApiUrl(apiEndpoints.stock.unbooked), {
        data: ids
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    dispatch(getUnbookedStock());
  };
}

export function pushOrdersApi(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(createApiUrl(apiEndpoints.stock.push), ids);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    dispatch(getUnbookedStock());
  };
}

export function updateOrderRows(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(createApiUrl(apiEndpoints.stock.updaterows), values);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    dispatch(getUnbookedStock());
  };
}

export function addOrderRows(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(createApiUrl(apiEndpoints.stock.add), values);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    dispatch(getUnbookedStock());
  };
}

export function deleteOrderRows(ids) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(createApiUrl(apiEndpoints.stock.deleteOrderRows), {
        data: ids
      });
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }

    dispatch(getUnbookedStock());
  };
}

export function updateOrders(values) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(createApiUrl(apiEndpoints.stock.updateorders), values);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    dispatch(getUnbookedStock());
  };
}
