import { apiConfig } from 'src/config';

export const apiEndpoints = {
  backups: {
    get: '/backups'
  },
  users: {
    list: '/users/active'
  },
  queuetogosettings: {
    getsettings: '/queuetogosettings/',
    updatebeastsettings: '/queuetogosettings/beastmode',
    updatesettings: '/queuetogosettings/'
  },
  queuetogocommand: {
    getCommand: '/QueueToGoCommand/',
    updateCommand: '/QueueToGoCommand/'
  },
  stock: {
    unbooked: '/inventorypusher/',
    delete: '/inventorypusher/',
    push: '/inventorypusher/pushbulk',
    events: '/inventorypusher/events',
    updaterows: '/inventorypusher/updaterowbulk',
    updateorders: '/inventorypusher/updateordersbulk',
    add: '/inventorypusher/addrowsbulk',
    deleteOrderRows: '/inventorypusher/deleteorderrows'
  },
  authentication: {
    authenticate: '/Authentication/authenticate',
    refresh: '/Authentication/refresh'
  },
  queuetogodashboard: {
    reset: '/queuetogodashboard/reset'
  },
  ticketmaster: {
    orders: '/ticketmaster'
  },
  queuetogoinstance: {
    start: '/QueueToGoInstance/start',
    kill: '/QueueToGoInstance/kill'
  },
  events: {
    section: '/stock/list/section',
    level: '/stock/list/level',
    owned: '/products/owned/',
    createPresigned: '/products/createPresigned',
    updatePresigned: '/products/updatePresigned',
    create: '/products/create',
    products: '/products'
  }
};

export const apiEndpointsWithVars = (vars) => {
  return {
    authentication: {},
    transaction: {
      new: `/transaction/${vars[0]}/${vars[1] ? 1 : 0}`
    },
    ticketmaster: {
      book: `/ticketmaster/book/${vars[0]}`
    },
    queuetogo: {
      event: `/queuetogosettings/event/${vars[0]}/`
    },
    queuetogodashboard: {
      download: `/queuetogodashboard/download/${vars[0]}/`,
      updatehit: `/queuetogodashboard/updatehit/${vars[0]}/`
    },
    images: {
      upload: `/images/${vars[0]}/upload`
    },
    apiKeys: {
      delete: `/apikeys/${vars[0]}`
    }
  };
};

export const createApiUrl = (endpoint) => `${apiConfig.apiBaseUrl}${endpoint}`;
export const createBackupApiUrl = (endpoint) =>
  `${apiConfig.backupApiBaseUrl}${endpoint}`;

export const isUrlToApi = (url) => {
  if (!url) return false;
  return (
    url.startsWith(apiConfig.apiBaseUrl) ||
    url.startsWith(apiConfig.backupApiBaseUrl)
  );
};

export const isUrlToRefresh = (url) => {
  if (!url) return false;
  return url.startsWith(
    `${apiConfig.apiBaseUrl}${apiEndpoints.authentication.refresh}`
  );
};
