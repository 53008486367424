import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // PRODUCTS : PRODUCTS
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.events.childeren.section} />
    },
    {
      exact: true,
      path: PATH_APP.events.root,
      component: () => <Redirect to={PATH_APP.events.childeren.section} />
    },
    {
      exact: true,
      path: PATH_APP.events.childeren.section,
      component: lazy(() => import('src/views/events/SectionEventsView'))
    },
    {
      exact: true,
      path: PATH_APP.events.childeren.level,
      component: lazy(() => import('src/views/events/LevelEventsView'))
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.settings,
      component: lazy(() =>
        import('src/views/queuetogo/SettingsView/SettingsView')
      )
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.client,
      component: lazy(() => import('src/views/queuetogo/ClientView/ClientView'))
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.beastmode,
      component: lazy(() =>
        import('src/views/queuetogo/BeastmodeView/BeastmodeView')
      )
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.instances,
      component: lazy(() =>
        import('src/views/queuetogo/InstancesView/InstancesView')
      )
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.sms,
      component: lazy(() => import('src/views/queuetogo/SmsView/SmsView'))
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.ticketmaster,
      component: lazy(() =>
        import('src/views/queuetogo/TicketmasterView/TicketmasterView')
      )
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.childeren.dashboard,
      component: lazy(() =>
        import('src/views/queuetogo/DashboardView/DashboardView')
      )
    },
    {
      exact: true,
      path: PATH_APP.queuetogo.root,
      component: () => <Redirect to={PATH_APP.queuetogo.childeren.dashboard} />
    },

    {
      exact: true,
      path: PATH_APP.transaction.root,
      component: lazy(() =>
        import('src/views/transactions/TransactionsView/TransactionsView')
      )
    },

    {
      exact: true,
      path: PATH_APP.stock.root,
      component: lazy(() =>
        import('src/views/stock/StockBookerView/StockBookerView')
      )
    },

    {
      exact: true,
      path: PATH_APP.backup.root,
      component: lazy(() => import('src/views/dboverview/dboverviewtable'))
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.management.user.profile,
      component: lazy(() => import('src/views/user/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.cards,
      component: lazy(() => import('src/views/user/UserCardsView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.list,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },
    {
      exact: true,
      path: PATH_APP.management.user.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },
    {
      exact: true,
      path: PATH_APP.management.root,
      component: () => <Redirect to={PATH_APP.management.user.profile} />
    },

    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;
