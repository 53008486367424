import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpoints } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const decodeJWT = (accessToken) => {
  if (!accessToken) {
    return { expired: true, user: null };
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  const expired = decoded.exp <= currentTime;

  return { isExpired: expired, user: decoded };
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
};

const refreshJWT = async (oldRefreshToken) => {
  try {
    const response = await axios.post(
      createApiUrl(apiEndpoints.authentication.refresh),
      {
        refreshToken: oldRefreshToken
      }
    );

    if (response.status !== 200) return null;

    const { jwt, refreshToken } = response.data;
    setSession(jwt, refreshToken);
    return true;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const handleJWT = async (accessToken, refreshToken) => {
  if (!accessToken) return null;

  const { isExpired, user } = decodeJWT(accessToken);

  if (isExpired) {
    accessToken = await refreshJWT(refreshToken);

    if (!accessToken) return null;
  }

  return { accessToken: accessToken, user };
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    const response = await axios.post(
      createApiUrl(apiEndpoints.authentication.authenticate),
      {
        email: email,
        password: password
      }
    );

    const { jwt, refreshToken } = response.data;
    const { user } = decodeJWT(jwt);
    setSession(jwt, refreshToken);
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function register({ email, password, firstName, lastName }) {
  return async (dispatch) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch(slice.actions.registerSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    setSession(null, null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export const failInitialize = (dispatch) =>
  dispatch(
    slice.actions.getInitialize({
      isAuthenticated: false,
      user: null
    })
  );

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      let accessToken = window.localStorage.getItem('accessToken');
      let refreshToken = window.localStorage.getItem('refreshToken');

      const { user } = await handleJWT(accessToken, refreshToken);

      if (user) {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: user
          })
        );
      } else {
        failInitialize(dispatch);
      }
    } catch (error) {
      console.error(error);
      failInitialize(dispatch);
    }
  };
}
