import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {
  createApiUrl,
  apiEndpoints,
  apiEndpointsWithVars
} from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  myKeys: []
};

const slice = createSlice({
  name: 'apiKey',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getKeysSuccess(state, action) {
      state.isLoading = false;
      state.myKeys = action.payload;
    },

    deleteKeySuccess(state, action) {
      state.isLoading = false;
      state.deleteError = false;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getApiKeys() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.apiKeys.owned)
      );
      dispatch(
        slice.actions.getKeysSuccess(
          response.data.sort((a, b) => {
            const aDate = new Date(a.createdAt);
            const bDate = new Date(b.createdAt);
            if (aDate > bDate) return -1;
            if (aDate < bDate) return 1;
            return 0;
          })
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteApiKey(keyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        createApiUrl(apiEndpointsWithVars([keyId]).apiKeys.delete)
      );
      dispatch(slice.actions.deleteKeySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasDeleteError(error));
    }
  };
}
