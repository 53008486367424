import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { createApiUrl, apiEndpointsWithVars } from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  imagesUpdated: false,
  productImages: []
};

const slice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProductImagesSuccess(state, action) {
      state.isLoading = false;
      state.productImages.push({
        productId: action.payload.productId,
        images: action.payload.data
      });
      state.imagesUpdated = !state.imagesUpdated;
    },

    resetProductImages(state, action) {
      state.productImages = [];
    }
  }
});

// Reducer
export default slice.reducer;

export function getProductImages(productId) {
  return async (dispatch) => {
    dispatch(slice.actions.resetProductImages());
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpointsWithVars([productId]).products.images)
      );
      dispatch(
        slice.actions.getProductImagesSuccess({
          data: response.data,
          productId: productId
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function downloadImage(imageId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      axios.get(createApiUrl(`/images/${imageId}/download`)).then((r) => {
        window.location.href = r.data;
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
