// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app'
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    loginUnprotected: path(ROOTS.auth, '/login-unprotected'),
    register: path(ROOTS.auth, '/register'),
    registerUnprotected: path(ROOTS.auth, '/register-unprotected'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance'
};

export const PATH_HOME = {
  components: '/components',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  events: {
    root: path(ROOTS.app, '/events'),
    childeren: {
      level: path(ROOTS.app, '/events/level'),
      section: path(ROOTS.app, '/events/section'),
      detail: path(ROOTS.app, '/events/detail/:eventId/')
    }
  },
  queuetogo: {
    root: path(ROOTS.app, '/queuetogo'),
    childeren: {
      dashboard: path(ROOTS.app, '/queuetogo/dashboard'),
      settings: path(ROOTS.app, '/queuetogo/settings'),
      beastmode: path(ROOTS.app, '/queuetogo/beastmode'),
      instances: path(ROOTS.app, '/queuetogo/instances'),
      sms: path(ROOTS.app, '/queuetogo/sms'),
      ticketmaster: path(ROOTS.app, '/queuetogo/ticketmaster'),
      client: path(ROOTS.app, '/queuetogo/client')
    }
  },
  backup: {
    root: path(ROOTS.app, '/backups')
  },
  transaction: {
    root: path(ROOTS.app, '/transaction')
  },
  stock: {
    root: path(ROOTS.app, '/stock')
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
    blog: {
      root: path(ROOTS.app, '/management/blog'),
      post: path(ROOTS.app, '/management/blog/post/:title'),
      postById: path(
        ROOTS.app,
        '/management/blog/post/portfolio-review-is-this-portfolio-too-creative'
      ),
      newPost: path(ROOTS.app, '/management/blog/new-post')
    }
  }
};
