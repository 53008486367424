import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {
  createApiUrl,
  apiEndpoints,
  apiEndpointsWithVars
} from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  ticketmasterOrders: []
};

const slice = createSlice({
  name: 'ticketmaster',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getTicketmasterOrdersSuccess(state, action) {
      state.isLoading = false;
      state.ticketmasterOrders = action.payload;
    },

    bookSuccess(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

export function getTicketmasterOrders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.ticketmaster.orders)
      );
      dispatch(slice.actions.getTicketmasterOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function bookOrder(orderId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpointsWithVars([orderId]).ticketmaster.book)
      );
      dispatch(slice.actions.bookSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
