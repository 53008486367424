import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import {
  createApiUrl,
  apiEndpoints,
  apiEndpointsWithVars
} from 'src/utils/requests';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  deleteError: false,
  loadingError: false,
  rows: [],
  command: '',
  eventData: {
    manifest: {},
    availableSections: []
  }
};

const slice = createSlice({
  name: 'queuetogo',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingError = true;
      state.error = action.payload;
    },

    hasDeleteError(state, action) {
      state.isLoading = false;
      state.deleteError = true;
      state.error = action.payload;
    },

    getQueueToGoSettingsSuccess(state, action) {
      state.isLoading = false;
      state.rows = action.payload;
    },

    getEventDataSuccess(state, action) {
      state.isLoading = false;
      state.eventData = action.payload;
    },

    updateHitSuccess(state, action) {
      state.isLoading = false;
    },

    updateQueueSettingsSuccess(state, action) {
      state.isLoading = false;
    },

    updateBeastSettingsSuccess(state, action) {
      state.isLoading = false;
    },
    updateQueueCommandSuccess(state, action) {
      state.isLoading = false;
    },
    getClientCommandSuccess(state, action) {
      state.isLoading = false;
      state.command = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getClientCommand() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.queuetogocommand.getCommand)
      );
      dispatch(slice.actions.getClientCommandSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function apiUpdateHit(sessionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(
          apiEndpointsWithVars([sessionId]).queuetogodashboard.updatehit
        )
      );
      dispatch(slice.actions.updateHitSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function apiDownloadServer(sessionId, ip) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = createApiUrl(
        apiEndpointsWithVars([sessionId]).queuetogodashboard.download
      );
      const response = await axios.get(url, { responseType: 'arraybuffer' });
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${ip}.moba`;
      link.click();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQueueToGoSettings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpoints.queuetogosettings.getsettings)
      );
      dispatch(slice.actions.getQueueToGoSettingsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventData(tmId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        createApiUrl(apiEndpointsWithVars([tmId]).queuetogosettings.event)
      );
      dispatch(slice.actions.getEventDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateQueueSettings(queuetogo) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpoints.queuetogosettings.updatesettings),
        queuetogo
      );
      dispatch(slice.actions.updateQueueSettingsSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function apiSubmitBeastModeSettings(settings) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpoints.queuetogosettings.updatebeastsettings),
        settings
      );
      dispatch(slice.actions.updateBeastSettingsSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function apiSubmitClientCommand(command) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        createApiUrl(apiEndpoints.queuetogocommand.updateCommand),
        command
      );
      dispatch(slice.actions.updateQueueCommandSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
